import { type Terminart, terminart } from '../dtos';

const terminartLabels = {
  STANDARDTERMIN: 'Standardtermin',
  ZUSATZTERMIN: 'Zusatztermin',
  ERSATZTERMIN: 'Ersatztermin',
} as const;

export const printTerminart = (input: Terminart): string => {
  switch (input) {
    case terminart.STANDARDTERMIN:
      return terminartLabels.STANDARDTERMIN;
    case terminart.ZUSATZTERMIN:
      return terminartLabels.ZUSATZTERMIN;
    case terminart.ERSATZTERMIN:
      return terminartLabels.ERSATZTERMIN;
    default: {
      // throw a typescript compiler error if a case is missing
      const exhaustiveCheck: never = input;
      throw new Error(`[VeranstaltungListing] Nicht implementierte Terminart: ${exhaustiveCheck}`);
    }
  }
};
