import {
  clusterzuordnung,
  type GekaufterZeitraumTag,
  type GetExpertenByThemaOutput,
  type GetRaeumeOutput,
  type GetStandorteOutput,
  type PlanungsinformationFromThemaRouter,
  type RessourcenBlockungen,
  ressourcenBlockungTypes,
} from '../../../../../dtos';
import { content } from './CalendarResourceOverview.content';
import {
  type CollapseInfo,
  type ErsatztrainerResourceGroup,
  type ExperteResourceGroup,
  type HaupttrainerResourceGroup,
  isErsatztrainerResource,
  isHaupttrainerResource,
  isNebentrainerResource,
  isTrainerOhneZuordnungResource,
  type NebentrainerResourceGroup,
  type RaumResourceGroup,
  type StandortResourceGroup,
  type TrainerOhneZuordnungResourceGroup,
} from './CalendarResrouceOverview.utils.types';
import {
  type CalendarEvent,
  CalendarExperteResource,
  CalendarRaumResource,
  type CalendarResource,
  CalendarStandortResource,
  isExperteResource,
  isRaumResource,
  isStandortResource,
} from './helperClasses';
import { type MbscCalendarColor, type MbscResource } from '@mobiscroll/react';
import { eachDayOfInterval, endOfDay, set, startOfDay } from 'date-fns';

const getGroupDefaultValues = (
  collapsInfo: CollapseInfo,
): {
  expertenGroup: ExperteResourceGroup;
  raeumeGroup: RaumResourceGroup;
  standorteGroup: StandortResourceGroup;
} => {
  const expertenGroup: ExperteResourceGroup = {
    id: ressourcenBlockungTypes.EXPERTE,
    name: content.calenderResourceGroupLabels.EXPERTE,
    collapsed: collapsInfo.experte,
    children: [],
  };

  const raeumeGroup: RaumResourceGroup = {
    id: ressourcenBlockungTypes.RAUM,
    name: content.calenderResourceGroupLabels.RAUM,
    children: [],
    collapsed: collapsInfo.raum,
  };

  const standorteGroup: StandortResourceGroup = {
    id: ressourcenBlockungTypes.STANDORT,
    name: content.calenderResourceGroupLabels.STANDORT,
    children: [],
    collapsed: collapsInfo.standort,
  };

  return {
    expertenGroup,
    raeumeGroup,
    standorteGroup,
  };
};

const getExpertenSubGroupDefaults = (
  planungsinformation: PlanungsinformationFromThemaRouter,
): {
  haupttrainerGroup: HaupttrainerResourceGroup;
  nebentrainerGroup: NebentrainerResourceGroup;
  ersatztrainerGroup: ErsatztrainerResourceGroup;
  trainerOhneZuordnungGroup: TrainerOhneZuordnungResourceGroup;
} => {
  const clusterAnteile = planungsinformation.clusteranteil?.split('/') ?? [];
  const clusteranteilA = clusterAnteile[0];
  const clusteranteilB = clusterAnteile[1];

  const haupttrainerGroup: HaupttrainerResourceGroup = {
    id: clusterzuordnung.HAUPTTRAINER,
    name: `${content.clusterzuordnungLabels.haupttrainer} - Cluster ${clusteranteilA}%`,
    children: [],
  };

  const nebentrainerGroup: NebentrainerResourceGroup = {
    id: clusterzuordnung.NEBENTRAINER,
    name: `${content.clusterzuordnungLabels.nebentrainer} - Cluster ${clusteranteilB}%`,
    children: [],
  };

  const ersatztrainerGroup: ErsatztrainerResourceGroup = {
    id: clusterzuordnung.ERSATZTRAINER,
    name: `${content.clusterzuordnungLabels.ersatztrainer} - Cluster C`,
    children: [],
  };

  const trainerOhneZuordnungGroup: TrainerOhneZuordnungResourceGroup = {
    id: clusterzuordnung.KEINEZUORDNUNG,
    name: `${content.clusterzuordnungLabels.keinezuordnung}`,
    children: [],
  };

  return {
    haupttrainerGroup,
    nebentrainerGroup,
    ersatztrainerGroup,
    trainerOhneZuordnungGroup,
  };
};

export const groupCalendarResources = (
  resources: CalendarResource[],
  collapseInfo: CollapseInfo,
  planungsinformation: PlanungsinformationFromThemaRouter,
  isOnlineVeranstaltung: boolean,
): MbscResource[] => {
  const { expertenGroup, raeumeGroup, standorteGroup } = getGroupDefaultValues(collapseInfo);
  const { haupttrainerGroup, nebentrainerGroup, ersatztrainerGroup, trainerOhneZuordnungGroup } = getExpertenSubGroupDefaults(planungsinformation);

  for (const resource of resources) {
    if (isHaupttrainerResource(resource)) {
      haupttrainerGroup.children.push(resource);
    }

    if (isNebentrainerResource(resource)) {
      nebentrainerGroup.children.push(resource);
    }

    if (isErsatztrainerResource(resource)) {
      ersatztrainerGroup.children.push(resource);
    }

    if (isTrainerOhneZuordnungResource(resource)) {
      trainerOhneZuordnungGroup.children.push(resource);
    }

    if (isStandortResource(resource)) {
      standorteGroup.children.push(resource);
    }

    if (isRaumResource(resource)) {
      raeumeGroup.children.push(resource);
    }
  }

  if (trainerOhneZuordnungGroup.children.length > 0) expertenGroup.children.push(trainerOhneZuordnungGroup);
  if (haupttrainerGroup.children.length > 0) expertenGroup.children.push(haupttrainerGroup);
  if (nebentrainerGroup.children.length > 0) expertenGroup.children.push(nebentrainerGroup);
  if (ersatztrainerGroup.children.length > 0) expertenGroup.children.push(ersatztrainerGroup);

  const groups: Array<RaumResourceGroup | StandortResourceGroup | ExperteResourceGroup> = isOnlineVeranstaltung ? [expertenGroup] : [raeumeGroup, standorteGroup, expertenGroup];

  return groups;
};

export const createCalendarResources = (
  expertepool: GetExpertenByThemaOutput,
  raeume: GetRaeumeOutput,
  standorte: GetStandorteOutput,
  startDate: Date,
  planungsinformation?: PlanungsinformationFromThemaRouter,
): CalendarResource[] => {
  const resources: CalendarResource[] = [];
  for (const raum of raeume) {
    resources.push(new CalendarRaumResource(raum));
  }

  for (const experte of expertepool) {
    resources.push(new CalendarExperteResource(experte, startDate.getFullYear(), planungsinformation));
  }

  for (const standort of standorte) {
    resources.push(new CalendarStandortResource(standort));
  }

  return resources;
};

type CalendarColor = MbscCalendarColor & { start: Date; end: Date };

const CALENDAR_RESOURCE_BACKGROUND_COLOR = '#DAFAC2';

const createRaumColor = (raumResource: CalendarRaumResource, availability: GekaufterZeitraumTag): CalendarColor => ({
  start: startOfDay(availability.start),
  // we remove a minute from the end date to avoid highlighting the next day (e.g. start: 2024-08-17T00:00:00, end: 2024-08-18T00:00:00)
  end: endOfDay(new Date(availability.end.valueOf() - 60_000)),
  resource: raumResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

const createStandortColor = (standortResource: CalendarStandortResource): MbscCalendarColor => ({
  recurring: { repeat: 'daily' },
  resource: standortResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

const createExperteColor = (experteResource: CalendarExperteResource): MbscCalendarColor => ({
  recurring: { repeat: 'daily' },
  resource: experteResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

const createEventColor = (date: Date) => ({
  start: startOfDay(date),
  end: endOfDay(date),
  background: '#C8D9FA',
});

export const createCalendarColors = (resources: CalendarResource[], events: CalendarEvent[]): MbscCalendarColor[] => {
  const colors: MbscCalendarColor[] = [];

  for (const resource of resources) {
    if (isRaumResource(resource)) {
      for (const availability of resource.raum.gekaufterZeitraum) {
        colors.push(createRaumColor(resource, availability));
      }
    }

    if (isStandortResource(resource)) {
      colors.push(createStandortColor(resource));
    }

    if (isExperteResource(resource)) {
      colors.push(createExperteColor(resource));
    }
  }

  for (const event of events) {
    if (event.isCurrentSelection) {
      for (const date of eachDayOfInterval(event)) {
        colors.push(createEventColor(date));
      }
    }
  }

  return colors;
};

export const convertCurrentlySelectedCalendarEventsToBlockungen = (events: CalendarEvent[]): RessourcenBlockungen => {
  const blockungen: RessourcenBlockungen = [];

  for (const event of events) {
    if (!event.isCurrentSelection) {
      continue;
    }

    for (const buchungsTag of eachDayOfInterval(event)) {
      blockungen.push({
        ressourceSapId: event.ressourceSapId,
        type: event.type,
        buchungsTag,
      });
    }
  }

  return blockungen;
};

export const convertTimeStringToDate = (timeString: string): Date => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return set(new Date(), { hours, minutes, seconds: 0, milliseconds: 0 });
};
