import { type Clusterzuordnung } from '../../../../dtos';
import { printClusterzuordnungTooltip } from '../../../../utils/clusterzuordnung';
import { printCluster } from './TerminExpertepoolCard.utils';
import { Tooltip, Typography } from '@mui/material';
import { type FC } from 'react';

export const ExpertePoolCell: FC<{ clusterzuordnung: Clusterzuordnung }> = (props: { readonly clusterzuordnung: Clusterzuordnung }) => (
  <Tooltip title={printClusterzuordnungTooltip(props.clusterzuordnung)} placement="right">
    <Typography variant="body2" height="100%" alignContent="center">
      {printCluster(props.clusterzuordnung)}
    </Typography>
  </Tooltip>
);
