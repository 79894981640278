import { type RessourcenBlockungType } from '../../../../../dtos';
import { BackendConfigContext } from '../../../../../utils/BackendInfrastructure';
import { type CalendarEvent, type CalendarResource } from '../CalendarResourceOverview/helperClasses';
import { DisplayCalendarEvent, DisplayCalendarResource } from './helperComponents';
import { ResourceCalendarHeader } from './ResourceCalendarHeader';
import { Eventcalendar, localeDe, type MbscCalendarColor, type MbscCellClickEvent, type MbscEventClickEvent, type MbscResource, setOptions } from '@mobiscroll/react';
import { useContext, useState } from 'react';

type ResourceCalendarProps = {
  readonly resources: MbscResource[];
  readonly events: CalendarEvent[];
  readonly colors: MbscCalendarColor[];
  readonly isFullscreen: boolean;
  readonly setTimeRange: (timeRange: { start: Date; end: Date }) => void;
  readonly year: number;
  readonly toggleExpansion?: (resourceType: RessourcenBlockungType, expand: boolean) => void;
  readonly initialDate?: Date;
  readonly cellClickHandler?: (event: MbscCellClickEvent) => void;
  readonly eventClickHandler?: (event: MbscEventClickEvent) => void;
};

setOptions({
  locale: localeDe,
  theme: 'material',
  themeVariant: 'light',
});

export const ResourceCalendar: React.FC<ResourceCalendarProps> = ({
  resources,
  events,
  colors,
  isFullscreen,
  toggleExpansion,
  setTimeRange,
  year,
  initialDate,
  cellClickHandler,
  eventClickHandler,
}: ResourceCalendarProps) => {
  const backendConfig = useContext(BackendConfigContext);
  const [weekCount, setWeekCount] = useState<number>();

  return (
    <Eventcalendar
      renderHeader={() => <ResourceCalendarHeader onViewSizeChange={(value: number) => setWeekCount(value)} />}
      colors={colors}
      data={events}
      resources={resources}
      defaultSelectedDate={initialDate ?? new Date()}
      locale={localeDe}
      onCellClick={cellClickHandler}
      onEventClick={eventClickHandler}
      onPageChange={({ firstDay, lastDay }) => setTimeRange({ start: firstDay, end: lastDay })}
      onResourceExpand={toggleExpansion ? (expandEvent): void => toggleExpansion(expandEvent.resource as RessourcenBlockungType, false) : undefined}
      onResourceCollapse={toggleExpansion ? (expandEvent): void => toggleExpansion(expandEvent.resource as RessourcenBlockungType, true) : undefined}
      showEventTooltip={false}
      renderResource={(resource: CalendarResource) => <DisplayCalendarResource resource={resource} year={year} hiveUrl={backendConfig.HiveAppUrl} />}
      event
      renderScheduleEvent={(event) => <DisplayCalendarEvent event={event.original as CalendarEvent} />}
      theme="material"
      themeVariant="light"
      height={isFullscreen ? '80vh' : 'calc(86vh - 240px)'} // we like magic numbers :)
      view={{
        timeline: {
          type: 'week',
          resolutionHorizontal: 'day',
          size: weekCount,
          weekNumbers: true,
          startDay: 1,
          endDay: 5,
        },
      }}
    />
  );
};
