import { type RaumBlockung, type Veranstaltung } from '../../../dtos';
import { getOrtBezeichnung, getOrtKuerzel, getStandortKuerzel, getStandortName } from '../../../utils/getBezeichnungen';

export const getOrtLabel = (veranstaltung: Veranstaltung): string => {
  const ortKuerzel = getOrtKuerzel(veranstaltung);
  const ortBezeichnung = getOrtBezeichnung(veranstaltung);
  const ortLabel = ortKuerzel && ortBezeichnung ? `${ortKuerzel} ${ortBezeichnung}` : null;

  if (ortLabel !== null) {
    return ortLabel;
  }

  return '-';
};

export const getRaumartLabel = (raumart: RaumBlockung['raumart']): string => {
  switch (raumart) {
    case 'standardraum':
      return 'Standardraum';
    case 'pcraum':
      return 'PC-Raum';
    case 'gruppenraum':
      return 'Gruppenraum';
    case 'produktionsraum':
      return 'Produktionsraum';
    default: {
      // error at build time if not all cases are covered
      const exhaustiveCheck: never = raumart;
      throw new Error(`[TerminOrtCard.utils] Nicht implementierte Raumart: ${exhaustiveCheck}`);
    }
  }
};

export const getStandortLabel = (veranstaltung: Veranstaltung): string => {
  const standortName = getStandortName(veranstaltung);
  const standortKuerzel = getStandortKuerzel(veranstaltung);
  return standortKuerzel && standortName ? `${standortKuerzel} ${standortName}` : '-';
};
