import { type Terminart, terminart as terminartEnum, type VeranstaltungCustomProperties } from '../../../../../../dtos';
import { printTerminart } from '../../../../../../utils/printTerminart';
import { printTerminanfrageTooltip, printTerminartTooltip } from './VeranstaltungPropertiesChanger.utils';
import { Box, FormControlLabel, Grid2, Radio, RadioGroup, Switch, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

type TerminartRadioButtonProps = { readonly terminart: Terminart };
const TerminartRadioButton: React.FC<TerminartRadioButtonProps> = (props: TerminartRadioButtonProps) => (
  <Tooltip disableInteractive title={<Typography variant="caption">{printTerminartTooltip(props.terminart)}</Typography>}>
    <FormControlLabel value={props.terminart} control={<Radio />} label={printTerminart(props.terminart)} />
  </Tooltip>
);

type VeranstaltungPropertiesChangerProps = {
  readonly themaManuelleTerminanfrage: boolean;
  readonly handleAttributeChange: (propertyData: VeranstaltungCustomProperties) => void;
};

export const VeranstaltungPropertiesChanger: React.FC<VeranstaltungPropertiesChangerProps> = (props: VeranstaltungPropertiesChangerProps) => {
  const { themaManuelleTerminanfrage, handleAttributeChange } = props;

  const [terminart, setTerminart] = useState<Terminart>(terminartEnum.STANDARDTERMIN);
  const [manuelleTerminanfrage, setManuelleTerminanfrage] = useState<boolean>(themaManuelleTerminanfrage);

  const handleTerminartChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newTerminart = (event.target as HTMLInputElement).value as Terminart;
    let newManuelleTerminanfrage = themaManuelleTerminanfrage;
    if (newTerminart === terminartEnum.ZUSATZTERMIN) {
      newManuelleTerminanfrage = true;
    }

    if (newTerminart === terminartEnum.ERSATZTERMIN) {
      newManuelleTerminanfrage = true;
    }

    setTerminart(newTerminart);
    setManuelleTerminanfrage(newManuelleTerminanfrage);

    handleAttributeChange({ terminart: newTerminart, manuelleTerminanfrage: newManuelleTerminanfrage });
  };

  return (
    <Grid2 container size={{ xs: 12 }} gap={2}>
      <Grid2 size={{ xs: 6 }}>
        <Typography sx={{ fontWeight: 'bold' }}>Angaben zur Veranstaltung</Typography>
      </Grid2>

      <Grid2 size={{ xs: 12 }} gap={10} display="flex">
        <Box>
          Terminart
          <Box>
            <RadioGroup value={terminart} name="terminart-radio-buttons" onChange={handleTerminartChange}>
              <TerminartRadioButton terminart={terminartEnum.STANDARDTERMIN} />
              <TerminartRadioButton terminart={terminartEnum.ZUSATZTERMIN} />
              <TerminartRadioButton terminart={terminartEnum.ERSATZTERMIN} />
            </RadioGroup>
          </Box>
        </Box>
        <Box>
          Terminanfrage
          <Box>
            <Tooltip disableInteractive title={<Typography variant="caption">{printTerminanfrageTooltip(terminart)}</Typography>}>
              <FormControlLabel
                control={<Switch sx={{ m: 1 }} disabled checked={manuelleTerminanfrage} />}
                label="Manuelle Terminanfrage"
                sx={{ '& > span': { color: 'unset !important' } }}
              />
            </Tooltip>
          </Box>
        </Box>
      </Grid2>
    </Grid2>
  );
};
