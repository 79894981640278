import { Header } from '../luComponents/Header';
import { VpnLock } from '@mui/icons-material';
import { Alert, Box, Stack, Typography } from '@mui/material';

type ConnectToVpnWarningProps = {
  readonly message: string;
};
export const ConnectToVpnWarning: React.FC<ConnectToVpnWarningProps> = ({ message }: ConnectToVpnWarningProps) => (
  <Box>
    <Header />
    <Stack alignItems="center" direction="row" justifyContent="center" sx={{ height: '100vh' }}>
      <Stack alignItems="center" direction="column" spacing={5}>
        <VpnLock sx={{ fontSize: '10vw', color: '#075BFA' }} />
        <Alert severity="warning">
          <Typography variant="h6">{message}</Typography>
        </Alert>
      </Stack>
    </Stack>
  </Box>
);
