import { BackendConfigContext } from '../../../utils/BackendInfrastructure';
import { content } from './content';
import { useContext } from 'react';
import { Link } from 'react-router';

type GesellschaftCellProps = { readonly gesellschaftId: string | null };

export const GesellschaftCell: React.FC<GesellschaftCellProps> = ({ gesellschaftId }: GesellschaftCellProps) => {
  const backendConfig = useContext(BackendConfigContext);

  if (gesellschaftId === null) {
    return content.nein;
  }

  return (
    <Link target="_blank" rel="noopener" to={`${backendConfig.HiveAppUrl}/organizations/${gesellschaftId}`}>
      {content.ja}
    </Link>
  );
};
