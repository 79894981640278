import { WithAuth } from '../auth/WithAuth';
import { ConnectToVpnWarning } from '../components/general/ConnectVpnWarning';
import { VivaPageLoading } from '../components/general/VivaPageLoading';
import { Footer, FOOTER_HEIGHT } from '../components/luComponents/Footer';
import { Header, HEADER_HEIGHT } from '../components/luComponents/Header';
import { CreateVeranstaltungSuccessSnackbar } from '../components/snackbars';
import { StyledMaterialDesignContent } from '../theme/lightHouseTheme';
import { trpc } from '../trpc';
import { Box } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { type ReactNode, useEffect, useState } from 'react';
import { Outlet } from 'react-router';

const CONTENT_HEIGHT = 100 - HEADER_HEIGHT - FOOTER_HEIGHT;

const BackendReachable: React.FC<{ readonly children: ReactNode }> = ({ children }: { readonly children: ReactNode }) => {
  const [hasBeenIdleForLongTime, setHasBeenIdleForLongTime] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setHasBeenIdleForLongTime(true);
    }, 5_000);
  }, []);

  /*
   * This ping serves two purposes:
   * - start backendLambda on app open to speed up first search results
   * - determine if the backend is reachable at all
   *   - this way, we can offer the user some troubleshooting ideas for the most common failures, like not being logged into VPN
   */
  const ping = trpc.ping.useQuery(undefined, { retry: false });

  if (ping.isError) {
    if (ping.error.data?.code === 'UNAUTHORIZED') {
      return <ConnectToVpnWarning message="Prüfe bitte mit der Haufe-IT, ob dein Microsoft-Account dazu berechtigt ist, auf VIVA zuzugreifen." />;
    } else {
      return <ConnectToVpnWarning message="Ein Backend-Fehler ist aufgetreten. Versuche, neu zu laden, oder wende dich ans Team VAMOS." />;
    }
  }

  if (ping.isLoading) {
    if (hasBeenIdleForLongTime) {
      return <ConnectToVpnWarning message="Um VIVA zu nutzen musst du mit dem Haufe VPN verbunden sein!" />;
    } else {
      return <VivaPageLoading msg="Wird geladen ..." />;
    }
  }

  return <>{children}</>;
};

export const LayoutWithAuth: React.FC = () => (
  <WithAuth>
    <BackendReachable>
      <Box height="100vh" width="100vw">
        <Header />
        <SnackbarProvider
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
            info: StyledMaterialDesignContent,
            warning: StyledMaterialDesignContent,
            createVeranstaltungSuccess: CreateVeranstaltungSuccessSnackbar,
            // Custom Snackbar: hang in your custom snackbar component here!
          }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={5_000}
          maxSnack={3}
        >
          <Box sx={{ height: `${CONTENT_HEIGHT}vh`, overflowX: 'hidden', overflowY: 'auto', maxWidth: '95vw', margin: 'auto' }}>
            <Outlet />
          </Box>
        </SnackbarProvider>
        <Box sx={{ position: 'absolute', bottom: '0px', width: '100%' }}>
          <Footer />
        </Box>
      </Box>
    </BackendReachable>
  </WithAuth>
);
