import { type Clusterzuordnung, clusterzuordnung, type Keyify } from '../dtos';

export type ClusterzuordnungAbbreviation = 'A' | 'B' | 'C' | '-';

const clusterzuordnungAbbreviation = {
  HAUPTTRAINER: 'A', // cluster A
  NEBENTRAINER: 'B', // cluster B
  ERSATZTRAINER: 'C', // cluster C
  KEINEZUORDNUNG: '-',
} as const satisfies Record<Keyify<Clusterzuordnung>, ClusterzuordnungAbbreviation>;

export const getClusterzuordnungAbbreviation = (givenClusterzuordnung: Clusterzuordnung): ClusterzuordnungAbbreviation => {
  let clusterAbbreviation: ClusterzuordnungAbbreviation;

  switch (givenClusterzuordnung) {
    case clusterzuordnung.HAUPTTRAINER:
      clusterAbbreviation = clusterzuordnungAbbreviation.HAUPTTRAINER;
      break;
    case clusterzuordnung.NEBENTRAINER:
      clusterAbbreviation = clusterzuordnungAbbreviation.NEBENTRAINER;
      break;
    case clusterzuordnung.ERSATZTRAINER:
      clusterAbbreviation = clusterzuordnungAbbreviation.ERSATZTRAINER;
      break;
    case clusterzuordnung.KEINEZUORDNUNG:
      clusterAbbreviation = clusterzuordnungAbbreviation.KEINEZUORDNUNG;
      break;
    default:
      throw new Error(`Could not find abbreviation for clusterzuordnung ${givenClusterzuordnung}`);
  }

  return clusterAbbreviation;
};

export const clusterzuordnungLabels = {
  haupttrainer: 'Haupttrainer',
  nebentrainer: 'Nebentrainer',
  ersatztrainer: 'Ersatztrainer',
  keinezuordnung: 'Trainer ohne Clusterzuordnung',
} as const satisfies Record<NonNullable<Clusterzuordnung>, string>;

export const printClusterzuordnungTooltip = (zuordnung: Clusterzuordnung): string => {
  if (zuordnung === clusterzuordnung.KEINEZUORDNUNG) {
    return 'Trainer:in wurde keinem Cluster in ContentServ zugeordnet.';
  }

  return clusterzuordnungLabels[zuordnung];
};
