import { type AblaufTag, type Veranstaltung, vivaStatus } from '../../../dtos';
import { trpc } from '../../../trpc';
import { formatTimeRangeForDateWithWeekday } from '../../../utils/dateFormat';
import { AccordionWithHeading } from '../../general/AccordionWithHeading';
import { EditButtonGroup } from '../../general/EditButtonGroup';
import { TimeRangeEdit } from '../../general/TimeRangeEdit';
import { content } from './TerminAblaufCard.content';
import { Draggable } from '@mobiscroll/react';
import { Add, Remove } from '@mui/icons-material';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

type TerminAblaufCardProps = {
  readonly veranstaltung: Veranstaltung;
};

const compareRange = (a: AblaufTag, b: AblaufTag): number => a.start.getTime() - b.start.getTime();

export const TerminAblaufCard: React.FC<TerminAblaufCardProps> = ({ veranstaltung }: TerminAblaufCardProps) => {
  const [ablauf, setAblauf] = useState<AblaufTag[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const veranstaltungUtils = trpc.useUtils().veranstaltung;
  const ablaufMutation = trpc.veranstaltung.updateAblauf.useMutation({
    onSuccess: () => {
      void veranstaltungUtils.invalidate();
    },
  });

  useEffect(() => {
    setAblauf(veranstaltung.ablauf.sort(compareRange));
  }, [veranstaltung]);

  const addAblauf = (): void =>
    setAblauf((prev) => {
      const newStart = new Date((prev.at(-1)?.start.getTime() ?? Date.now()) + 24 * 60 * 60 * 1_000);
      const newEnd = new Date((prev.at(-1)?.end.getTime() ?? Date.now()) + 24 * 60 * 60 * 1_000);
      return [...prev, { start: newStart, end: newEnd }];
    });

  const removeDay = (): void => {
    setAblauf((prev) => prev.slice(0, -1));
  };

  const onEditCancel = (): void => {
    setAblauf(veranstaltung.ablauf);
  };

  const onSave = async (): Promise<void> => {
    await ablaufMutation.mutateAsync({ veranstaltungId: veranstaltung.id, ablauf });
    enqueueSnackbar(content.ABLAUF_GEAENDERT, { variant: 'success' });
  };

  const onTimeChange = (indexTimeChange: number, start: Date | null, end: Date | null): void => {
    setAblauf((prev) => {
      const newAblaufArray = [...prev];
      newAblaufArray[indexTimeChange] = { start: start ?? prev[indexTimeChange].start, end: end ?? prev[indexTimeChange].end };
      return newAblaufArray;
    });
  };

  return (
    <Draggable id="ablauf">
      <AccordionWithHeading heading={content.HEADING}>
        <Stack>
          {veranstaltung.vivaStatus === vivaStatus.INPLANUNG && <EditButtonGroup isEditMode={isEditMode} setEditMode={setIsEditMode} onSave={onSave} onCancel={onEditCancel} />}
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}
          >
            {ablauf.map((range, idx) => (
              <TimelineItem key={range.start.getTime()}>
                <TimelineOppositeContent align="right">{idx + 1}. Tag</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  {idx + 1 < ablauf.length ? <TimelineConnector /> : null}
                </TimelineSeparator>
                <TimelineContent>
                  {isEditMode ? (
                    <TimeRangeEdit idx={idx} start={range.start} end={range.end} onChange={onTimeChange} autoArrange />
                  ) : (
                    <Typography>{formatTimeRangeForDateWithWeekday(range.start, range.end)}</Typography>
                  )}
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
          {isEditMode && (
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Button startIcon={<Remove />} color="error" onClick={removeDay}>
                {content.TAG_ENTFERNEN}
              </Button>
              <Button onClick={addAblauf} startIcon={<Add />} sx={{ borderRadius: 1 }}>
                {content.TAG_HINZUFUEGEN}
              </Button>
            </Stack>
          )}
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
