import { type GetAutomatisierteTerminanfragenByThemaOutput } from '../../../dtos';
import { formatDate } from '../../../utils/dateFormat';
import { type TypedGridColDef } from '../../../utils/gridHelper';
import { LinkWithCopyButton } from '../../general/LinkWithCopyButton';
import { VaStatusChip } from '../../general/VaStatusChip';
import { StatusTerminanfrageHive } from '../termin/TerminExperteCard/StatusTerminanfrageHive';
import { content } from './AutomatisierteTerminanfragenTable.content';
import { Box } from '@mui/material';
import { DataGridPro, type GridColDef, type GridRowParams } from '@mui/x-data-grid-pro';
import { type ReactElement, useCallback, useEffect, useState } from 'react';

type AutomatisierteTerminanfragenTableProps = {
  readonly data: GetAutomatisierteTerminanfragenByThemaOutput;
  readonly isLoading?: boolean;
  readonly hiveUrl: string;
  readonly filterReset?: boolean;
};

const getAblaufRangeAsString = (ablauf: Array<{ start: Date; end: Date }>): string => {
  if (ablauf.length === 0) {
    return content.noInfoAvailable;
  }

  const firstRange = ablauf[0];
  const lastRange = ablauf[ablauf.length - 1];

  return `${firstRange.start.toLocaleDateString()} - ${lastRange.end.toLocaleDateString()}`;
};

export const AutomatisierteTerminanfragenTable: React.FC<AutomatisierteTerminanfragenTableProps> = ({
  data,
  isLoading,
  hiveUrl,
  filterReset,
}: AutomatisierteTerminanfragenTableProps) => {
  const columnsOuter: Array<TypedGridColDef<GetAutomatisierteTerminanfragenByThemaOutput[number]>> = [
    {
      field: 'buchungsnummer',
      headerName: content.columnLabels.buchungsnummer,
      flex: 0.5,
      renderCell: (params): ReactElement => <LinkWithCopyButton text={params.value} to={`/thema/${params.row.produktSapId}`} tooltip={content.tooltips.zumThemaWechseln} />,
    },
    { field: 'titel', headerName: content.columnLabels.titel, flex: 3 },
    { field: 'akaProduktId', headerName: content.columnLabels.akaProduktId, flex: 0.5 },
    { field: 'verknuepfungBlopUndPraesenzseminar', headerName: content.columnLabels.verknuepfungBlopUndPraesenzseminar, flex: 0.5 },
  ];

  const columnsInner: (themaSapId: number) => Array<GridColDef<GetAutomatisierteTerminanfragenByThemaOutput[number]['veranstaltungen'][number]>> = useCallback(
    (themaSapId: number) => [
      {
        field: 'akaVeranstaltungId',
        headerName: content.columnLabels.akaVeranstaltungId,
        flex: 1,
        renderCell: (params): ReactElement => (
          <LinkWithCopyButton text={params.value} to={`/thema/${themaSapId}/veranstaltung/${params.row.veranstaltungId}`} tooltip={content.tooltips.zurVeranstaltungWechseln} />
        ),
      },
      {
        field: 'ablauf',
        headerName: content.columnLabels.vaAblauf,
        flex: 1,
        valueGetter: (ablauf: Array<{ start: Date; end: Date }>): string => getAblaufRangeAsString(ablauf),
      },
      {
        field: 'vaStatus',
        headerName: content.columnLabels.vaStatus,
        flex: 0.5,
        renderCell: (params): ReactElement => <VaStatusChip sapStatus={params.row.sapStatus} vivaStatus={params.row.vivaStatus} />,
      },
      {
        field: 'experteName',
        headerName: content.columnLabels.trainer,
        flex: 1,
        renderCell: (params): ReactElement => (
          <LinkWithCopyButton
            text={`${params.row.experteBlockung.experte.vorname} ${params.row.experteBlockung.experte.nachname}`}
            to={`${hiveUrl}/experten/${params.row.experteBlockung.experteSapId}`}
            tooltip={content.tooltips.zuHiveWechseln}
          />
        ),
      },
      {
        field: 'experteBlockung.zeitraeume',
        headerName: content.columnLabels.blockungsablauf,
        flex: 1,
        valueGetter: (_value, row): string => getAblaufRangeAsString(row.experteBlockung.zeitraeume),
      },
      {
        field: 'experteBlockung.hiveTerminanfrageStatus',
        headerName: content.columnLabels.terminanfrageStatus,
        flex: 0.75,
        renderCell: (params): ReactElement => <StatusTerminanfrageHive experteBlockung={params.row.experteBlockung} />,
      },
      {
        field: 'experteBlockung.hiveExperteAnfragedatum',
        headerName: content.columnLabels.terminanfrageAnfrageDatum,
        flex: 1,
        valueGetter: (_value, row): string => formatDate(row.experteBlockung.hiveExperteAnfragedatum),
      },
    ],
    [hiveUrl],
  );

  const getDetailPanelContent = useCallback(
    (params: GridRowParams<GetAutomatisierteTerminanfragenByThemaOutput[number]>): ReactElement => (
      <Box margin={4}>
        <DataGridPro columns={columnsInner(params.row.produktSapId)} rows={params.row.veranstaltungen} hideFooter />
      </Box>
    ),
    [columnsInner],
  );

  const [muiTableKey, setMuiTableKey] = useState<number>(1);

  useEffect(() => {
    if (filterReset) {
      setMuiTableKey(muiTableKey + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterReset]);

  return (
    <DataGridPro
      key={muiTableKey}
      columns={columnsOuter}
      rows={data}
      autoPageSize
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={(): 'auto' => 'auto'}
      hideFooter
      loading={isLoading}
      showCellVerticalBorder={false}
      showColumnVerticalBorder={false}
    />
  );
};
