import {
  type CreateVeranstaltungInput,
  type CreateVeranstaltungOutput,
  cservOnlineTools,
  type RessourcenBlockungen,
  terminart,
  type ThemaFromThemaRouter,
  type VeranstaltungCustomProperties,
} from '../../../dtos';
import { trpc } from '../../../trpc';
import { getIsOnlineThema } from '../../../utils/getBezeichnungen';
import { getItemFromSessionStorage } from '../../../utils/sessionStorage';
import { generateCreateVeranstaltungErrorSnackbar, generateCreateVeranstaltungErrorSnackbarWithFehlerId } from '../../snackbars';
import { CalendarResourceOverview } from './planung/CalendarResourceOverview';
import { Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router';

type CreateVeranstaltungProps = {
  readonly thema: Pick<ThemaFromThemaRouter, 'id' | 'planungsinformation' | 'produktSapId' | 'manuelleTerminanfrage' | 'isOnline'>;
};

export const CreateVeranstaltung: React.FC<CreateVeranstaltungProps> = (props: CreateVeranstaltungProps) => {
  const { thema } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const navigate = useNavigate();

  const trpcUtils = trpc.useUtils();
  const createVeranstaltungMutation = trpc.veranstaltung.createVeranstaltung.useMutation({
    onSuccess: (createVeranstaltungOutput: CreateVeranstaltungOutput) => {
      // Custom Snackbar: use your custom snackbar component like this!
      enqueueSnackbar({ variant: 'createVeranstaltungSuccess', persist: true, veranstaltungId: createVeranstaltungOutput.veranstaltungId });
      void trpcUtils.invalidate();
    },
    onError: (error) => {
      const fehlerId = getItemFromSessionStorage('latest-X-Viva-Request-Id');

      const snackbar = fehlerId === null ? generateCreateVeranstaltungErrorSnackbar(error) : generateCreateVeranstaltungErrorSnackbarWithFehlerId({ error, fehlerId });

      enqueueSnackbar(snackbar.snackbarNode, snackbar.options);

      void trpcUtils.invalidate();
    },
  });

  const toggleFullscreen = (): void => {
    setIsFullscreen((prev) => !prev);
  };

  const onSave = async (blockungen: RessourcenBlockungen, properties?: VeranstaltungCustomProperties): Promise<void> => {
    const input: CreateVeranstaltungInput = {
      themaId: thema.id,
      blockungen,
      terminart: properties?.terminart ?? terminart.STANDARDTERMIN,
      manuelleTerminanfrage: properties?.manuelleTerminanfrage,
      onlineTool: getIsOnlineThema(thema) ? cservOnlineTools.ZOOM : null,
    };

    await createVeranstaltungMutation.mutateAsync(input);
  };

  const onCancel = async (): Promise<void> => {
    await navigate(`/thema/${thema.produktSapId}`);
  };

  return (
    <Paper elevation={0} sx={{ width: '100%', height: '100%' }}>
      <CalendarResourceOverview
        onSave={onSave}
        onCancel={onCancel}
        thema={thema}
        isFullscreen={isFullscreen}
        toggleFullscreen={toggleFullscreen}
        initDate={new Date()}
        veranstaltung={null}
      />
    </Paper>
  );
};
