import { BackendConfigContext } from '../../../../utils/BackendInfrastructure';
import { type ExperteWithId, printName } from './TerminExpertepoolCard.utils';
import { useContext } from 'react';
import { Link } from 'react-router';

type ExperteNameCellProps = {
  readonly experte: ExperteWithId;
};

export const ExperteNameCell: React.FC<ExperteNameCellProps> = ({ experte }: ExperteNameCellProps) => {
  const backendConfig = useContext(BackendConfigContext);

  return (
    <Link target="_blank" rel="noopener" to={`${backendConfig.HiveAppUrl}/experten/${experte.experteSapId}`}>
      {printName(experte)}
    </Link>
  );
};
