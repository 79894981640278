import { type AkaVeranstaltungId, type UpdateStatusOfVeranstaltungenOutput, type Veranstaltung, vivaStatus } from '../../../../dtos';
import { trpc } from '../../../../trpc';
import { formatDate } from '../../../../utils/dateFormat';
import { experteName } from '../../../../utils/experteUtils';
import { getIsOnlineVeranstaltung, getOnlineOrtKuerzel, getOrtKuerzel, getRegionName } from '../../../../utils/getBezeichnungen';
import { getVeranstaltungEnd, getVeranstaltungStart } from '../../../../utils/getVeranstaltungStartEnd';
import { type GridFilterModelExtended } from '../../../../utils/MuiSchemas';
import { printTerminart } from '../../../../utils/printTerminart';
import { LinkWithCopyButton } from '../../../general/LinkWithCopyButton';
import { TrpcLoadingInfo } from '../../../general/TrpcLoadingInfo';
import { VaStatusChip } from '../../../general/VaStatusChip';
import { generateVeranstaltungListingErrorSnackbar, generateVeranstaltungListingSuccessSnackbar, veranstaltungListingBackendErrorMessage } from '../../../snackbars';
import { FehlerCell } from './FehlerCell';
import { content } from './VeranstaltungListing.content';
import { loadFilterModel, persistDataGridModel } from './VeranstaltungListing.utils';
import { Button, Grid2, Stack } from '@mui/material';
import { DataGridPro, gridClasses, type GridColDef, type GridFilterModel, type GridRowSelectionModel, type GridSortModel } from '@mui/x-data-grid-pro';
import { deDE } from '@mui/x-data-grid-pro/locales';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { z } from 'zod';

const veranstaltungsColumns: Array<GridColDef<Veranstaltung>> = [
  {
    field: 'id',
    headerName: content.columnLabels.id,
    flex: 0.7,
    valueGetter: (_value, row): AkaVeranstaltungId => row.akaVeranstaltungId,
    renderCell: (params) => (
      <LinkWithCopyButton text={params.row.akaVeranstaltungId} to={`/thema/${params.row.thema.produktSapId}/veranstaltung/${params.row.id}`} tooltip="Zur Veranstaltung wechseln" />
    ),
  },
  { field: 'veranstaltungSapId', headerName: content.columnLabels.sapid, flex: 0.7 },
  { field: 'start', headerName: content.columnLabels.start, flex: 0.5, renderCell: (params) => formatDate(getVeranstaltungStart(params.row)) },
  { field: 'end', headerName: content.columnLabels.end, flex: 0.5, renderCell: (params) => formatDate(getVeranstaltungEnd(params.row)) },
  {
    field: 'status',
    headerName: content.columnLabels.status,
    flex: 1,
    renderCell: (params) => <VaStatusChip sapStatus={params.row.sapStatus} vivaStatus={params.row.vivaStatus} />,
  },
  {
    field: 'regionName',
    headerName: content.columnLabels.regionName,
    flex: 1,
    renderCell: (params) => (getIsOnlineVeranstaltung(params.row) ? 'Online' : getRegionName(params.row)),
  },
  {
    field: 'ortKuerzel',
    headerName: content.columnLabels.ortKuerzel,
    flex: 1,
    renderCell: (params) => (getIsOnlineVeranstaltung(params.row) ? getOnlineOrtKuerzel(params.row.onlineTool) : getOrtKuerzel(params.row)),
  },
  {
    field: 'experten',
    headerName: content.columnLabels.experten,
    renderCell: (params): string => {
      const experteNamen = params.row.experteBlockungen.map((experteBlockung) => experteName(experteBlockung.experte)).join(', ');
      if (!experteNamen) {
        return 'Unbekannt';
      }

      return experteNamen;
    },
    flex: 1,
  },
  {
    field: 'terminart',
    headerName: content.columnLabels.terminart,
    renderCell: (params) => printTerminart(params.row.terminart),
    flex: 1,
  },
  {
    field: 'fehler',
    headerName: content.columnLabels.fehler,
    renderCell: FehlerCell,
    flex: 0.5,
  },
];

type VeranstaltungListingProps = {
  readonly themaId: number;
};

export const VeranstaltungListing: React.FC<VeranstaltungListingProps> = ({ themaId }: VeranstaltungListingProps) => {
  const vaQuery = trpc.veranstaltung.getVeranstaltungenByThemaId.useQuery({ themaId });
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch } = vaQuery;
  const changeStatusMutation = trpc.planung.updateStatusOfVeranstaltungen.useMutation({
    onError: () => enqueueSnackbar(veranstaltungListingBackendErrorMessage, { variant: 'error' }),
    onSuccess: (mutateResult: UpdateStatusOfVeranstaltungenOutput) => {
      if (mutateResult.length > 0) {
        const successVeranstaltungen = mutateResult.filter((result) => result.successMessages.length > 0);
        if (successVeranstaltungen.length > 0) {
          const snackbar = generateVeranstaltungListingSuccessSnackbar(successVeranstaltungen);
          enqueueSnackbar(snackbar.snackbarNode, snackbar.options);
        }

        const failedVeranstaltungen = mutateResult.filter((result) => result.errorMessages.length > 0);
        if (failedVeranstaltungen.length > 0) {
          const snackbar = generateVeranstaltungListingErrorSnackbar(failedVeranstaltungen);
          enqueueSnackbar(snackbar.snackbarNode, snackbar.options);
        }

        void refetch();
      } else {
        enqueueSnackbar(content.keineVeranstaltungenAenderung, { variant: 'info' });
      }
    },
  });

  const [gridSelection, setGridSelection] = useState<GridRowSelectionModel>();
  const [filterModel, setFilterModel] = useState<GridFilterModelExtended | undefined>(loadFilterModel());
  const [sortModel, setSortModel] = useState<GridSortModel>([{ sort: 'desc', field: 'start' }]);
  const handleChangeStatusForSelectedVAs = async (newStatus: typeof vivaStatus.ABGESCHLOSSEN | typeof vivaStatus.FREIGEGEBEN): Promise<void> => {
    if (!gridSelection || gridSelection.length === 0) {
      enqueueSnackbar(content.keineVeranstaltungenAuswahl, { variant: 'warning' });
      return;
    }

    await changeStatusMutation.mutateAsync({
      newStatus,
      idsToChange: z.array(z.coerce.number()).parse(gridSelection),
    });
  };

  return (
    <Grid2 container>
      <Grid2 size={{ xs: 12 }}>
        <Grid2 container justifyContent="end" sx={{ paddingBottom: 1 }}>
          {gridSelection && gridSelection.length > 0 && (
            <Grid2 size={{ xs: 3 }}>
              <Button disabled={changeStatusMutation.isPending} variant="outlined" onClick={async () => await handleChangeStatusForSelectedVAs(vivaStatus.ABGESCHLOSSEN)}>
                {content.buttons.abgeschlossen}
              </Button>
              <Button disabled={changeStatusMutation.isPending} variant="outlined" onClick={async () => await handleChangeStatusForSelectedVAs(vivaStatus.FREIGEGEBEN)}>
                {content.buttons.freigegeben}
              </Button>
            </Grid2>
          )}
        </Grid2>
        <Stack spacing={1}>
          <TrpcLoadingInfo trpcQuery={vaQuery} entity="Veranstaltungen">
            {data && (
              <DataGridPro
                columns={veranstaltungsColumns}
                rows={data}
                rowHeight={80}
                checkboxSelection
                pagination
                pageSizeOptions={[5, 10, 50, 100]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                filterModel={filterModel as unknown as GridFilterModel}
                onFilterModelChange={(newModel) => {
                  setFilterModel(newModel);
                  persistDataGridModel('filter', newModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newModel) => {
                  setSortModel(newModel);
                  persistDataGridModel('sort', newModel);
                }}
                onRowSelectionModelChange={(newSelection) => setGridSelection(newSelection)}
                rowSelectionModel={gridSelection}
                disableRowSelectionOnClick
                sx={{
                  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                    outline: 'none',
                  },
                  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                    outline: 'none',
                  },
                }}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              />
            )}
          </TrpcLoadingInfo>
        </Stack>
      </Grid2>
    </Grid2>
  );
};
