import { type BackendConfigJson } from '../utils/BackendInfrastructure';
import { msalConfig } from './config';
import { type AuthenticationResult, type EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';

export class AzureApplication extends PublicClientApplication {
  public constructor(config: BackendConfigJson) {
    super(msalConfig(config));

    const accounts = this.getAllAccounts();
    if (accounts.length > 0) {
      this.setActiveAccount(accounts[0]);
    }

    this.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        this.setActiveAccount(account);
      }
    });
  }
}
