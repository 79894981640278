import { type GetPlanungsinformationOutput } from '../../../../dtos';
import { AccordionWithHeading } from '../../../general/AccordionWithHeading';
import { ContentLoading } from '../../../general/ContentLoading';
import { GesellschaftCell } from '../GesellschaftCell';
import { type TerminProps } from '../utils';
import { ExperteNameCell } from './ExperteNameCell';
import { ExpertePoolCell } from './ExpertePoolCell';
import { content } from './TerminExpertepoolCard.content';
import {
  clusterField,
  type ExperteWithId,
  nextYear,
  printAnrede,
  printAuslastungAktuellesJahrLabel,
  printAuslastungForJahr,
  printAusschlusszeiten,
  printClusteranteil,
  printEinsatzort,
  printEmail,
  printExperteSapId,
  printIndividuelleProzentverteilung,
  printTitel,
  thisYear,
} from './TerminExpertepoolCard.utils';
import { Draggable } from '@mobiscroll/react';
import { DataGridPro, gridClasses, type GridColDef, type GridSortModel } from '@mui/x-data-grid-pro';
import { deDE } from '@mui/x-data-grid-pro/locales';
import { useState } from 'react';

export const TerminExpertepoolCard: React.FC<TerminProps> = (props: TerminProps) => {
  const { planungsinformationQuery } = props;
  const [sortModel, setSortModel] = useState<GridSortModel>([{ sort: 'asc', field: clusterField }]);

  if (planungsinformationQuery.isError) {
    throw new Error(planungsinformationQuery.error.message);
  }

  if (planungsinformationQuery.isLoading || !planungsinformationQuery.data) {
    return (
      <Draggable id={content.draggableId}>
        <AccordionWithHeading heading={content.heading}>
          <ContentLoading />
        </AccordionWithHeading>
      </Draggable>
    );
  }

  const planungsinformation: GetPlanungsinformationOutput = planungsinformationQuery.data;

  const expertepoolWithId: ExperteWithId[] = planungsinformation.expertepool.map((experte) => ({
    ...experte,
    // id field is necessary for DataGrid
    id: experte.experteSapId,
  }));

  const columns: Array<GridColDef<ExperteWithId>> = [
    { field: 'name', headerName: content.columnLabels.name, flex: 1, renderCell: (params) => <ExperteNameCell experte={params.row} /> },
    { field: 'anrede', headerName: content.columnLabels.anrede, flex: 1, valueGetter: (_value, row) => printAnrede(row) },
    { field: 'titel', headerName: content.columnLabels.titel, flex: 1, valueGetter: (_value, row) => printTitel(row) },
    { field: clusterField, headerName: content.columnLabels.cluster, flex: 1, renderCell: (params) => <ExpertePoolCell clusterzuordnung={params.row.clusterzuordnung} /> },
    {
      field: 'individuelleProzentverteilung',
      headerName: content.columnLabels.individuelleProzentverteilung,
      flex: 1,
      valueGetter: (_value, row) => printIndividuelleProzentverteilung(row),
    },
    { field: 'auslastungDiesesJahr', headerName: printAuslastungAktuellesJahrLabel(thisYear), flex: 1, valueGetter: (_value, row) => printAuslastungForJahr(thisYear, row) },
    { field: 'auslastungNaechstesJahr', headerName: printAuslastungAktuellesJahrLabel(nextYear), flex: 1, valueGetter: (_value, row) => printAuslastungForJahr(nextYear, row) },
    { field: 'einsatzort', headerName: content.columnLabels.einsatzort, flex: 1, valueGetter: (_value, row) => printEinsatzort(row) },
    {
      field: 'ausschlusszeiten',
      headerName: content.columnLabels.ausschlusszeiten,
      flex: 1,
      valueGetter: (_value, row) => printAusschlusszeiten(row),
    },
    {
      field: 'gesellschaftId',
      headerName: content.columnLabels.gesellschaft,
      flex: 1,
      renderCell: (params) => <GesellschaftCell gesellschaftId={params.row.gesellschaftId} />,
    },
    {
      field: 'email',
      headerName: content.columnLabels.email,
      flex: 1,
      valueGetter: (_value, row) => printEmail(row),
    },
    {
      field: 'sapId',
      headerName: content.columnLabels.sapId,
      flex: 1,
      valueGetter: (_value, row) => printExperteSapId(row),
    },
  ];

  return (
    <Draggable id={content.draggableId}>
      <AccordionWithHeading heading={content.heading} subheading={content.subheading + ' ' + printClusteranteil(planungsinformation)}>
        <DataGridPro
          columns={columns}
          rows={expertepoolWithId}
          pagination
          pageSizeOptions={[5, 10, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          sortModel={sortModel}
          onSortModelChange={(newModel) => {
            setSortModel(newModel);
          }}
          disableRowSelectionOnClick
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none',
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: 'none',
            },
          }}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        />
      </AccordionWithHeading>
    </Draggable>
  );
};
