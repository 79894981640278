import { Box, Grid2, LinearProgress, Stack, Typography } from '@mui/material';

type VivaPageLoadingProps = {
  readonly msg: string;
};

export const VivaPageLoading: React.FC<VivaPageLoadingProps> = ({ msg }: VivaPageLoadingProps) => (
  <Box>
    <Stack alignItems="center" direction="row" justifyContent="center" sx={{ height: '100vh' }}>
      <Stack alignItems="center" direction="column" spacing={5}>
        <Grid2 container alignItems="center">
          <Grid2 paddingRight="10px">
            <Typography color="primary" variant="h2">
              Viva
            </Typography>
          </Grid2>
          <img alt="Haufe Akademie Logo" src="/assets/images/haufe/Haufe_Akademie_Logo_negativ.svg" style={{ maxHeight: '10vh', backgroundColor: '#075BFA' }} />
          <Grid2 />
        </Grid2>
        <Grid2 size={{ xs: 12 }} width="100%">
          <LinearProgress sx={{ height: '12px', width: '100%' }} />
          <Typography fontStyle="italic" textAlign="center" fontSize="1.2em">
            {msg}
          </Typography>
        </Grid2>
      </Stack>
    </Stack>
  </Box>
);
