import './App.css';
import { appRouter } from './appRouter';
import { getIdToken } from './auth/getToken';
import { ReactQueryDevtoolsForProduction } from './components/ReactQueryDevtoolsForProduction';
import lightHouseTheme from './theme/lightHouseTheme';
import { queryClient, trpc, trpcClient } from './trpc';
import { BackendConfigContext } from './utils/BackendInfrastructure';
import { type IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ScopedCssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useContext } from 'react';
import { RouterProvider } from 'react-router';

const TrpcProvider = trpc.Provider;

type AppProps = {
  readonly msalInstance: IPublicClientApplication;
};

const App: React.FC<AppProps> = ({ msalInstance }: AppProps) => {
  const backendConfig = useContext(BackendConfigContext);
  const trpcClientInstance = trpcClient(getIdToken(msalInstance), backendConfig.AppBackendUrl);

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={lightHouseTheme}>
        <ScopedCssBaseline>
          <TrpcProvider client={trpcClientInstance} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <ReactQueryDevtoolsForProduction />
              <RouterProvider router={appRouter} />
            </QueryClientProvider>
          </TrpcProvider>
        </ScopedCssBaseline>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
