import { type BackendConfigJson, backendConfigJsonSchema } from './backendConfigJsonSchema';
import { createContext } from 'react';

const localhostDefaultConfig: BackendConfigJson = {
  AppBackendUrl: import.meta.env.VITE_APP_BACKEND_URL ?? 'http://localhost:4444/api/',
  AzureAdClientId: '77c77ea6-1f7d-470c-85eb-10fdf813846e',
  AzureAdTenantId: '88d0a5a9-d61a-4dd6-9a75-6637f3fe6bd8',
  AzureAdRedirectUri: 'https://va.vamos.ban.akadev.de/auth/redirect',
  VivaLegacyAppUrl: 'viva.vamos.ban.akadev.de', // legacy app main URL, since it's currently not planned to start legacy app locally
  HiveAppUrl: 'https://hive.prml.ban.akadev.de',
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- react contexts are named in PascalCase
export const BackendConfigContext = createContext(localhostDefaultConfig);

export const getBackendConfig = async (): Promise<BackendConfigJson> => {
  if (window.location.hostname === 'localhost') {
    return localhostDefaultConfig;
  }

  const config = await fetch('/backend-config.json')
    .then(async (res) => await res.json())
    .catch((error) => {
      throw error;
    });

  const parsedConfig = backendConfigJsonSchema.safeParse(config);
  if (!parsedConfig.success) {
    throw parsedConfig.error;
  }

  return parsedConfig.data;
};
