import { type GetPlanungsinformationOutput } from '../../../../dtos';
import { trpc } from '../../../../trpc';
import { EditButtonGroup } from '../../../general/EditButtonGroup';
import { content } from './TerminPlanungsstatusCard.content';
import { FormControlLabel, Stack, Switch } from '@mui/material';
import { useSnackbar } from 'notistack';
import { type ChangeEvent, useState } from 'react';

type ManuelleTerminanfrageProps = {
  readonly planungsinformation: GetPlanungsinformationOutput;
  readonly themaId: number;
};

export const ManuelleTerminanfrage: React.FC<ManuelleTerminanfrageProps> = ({ planungsinformation, themaId }: ManuelleTerminanfrageProps) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [terminanfrage, setTerminanfrage] = useState<boolean>(planungsinformation.manuelleTerminanfrage);

  const { enqueueSnackbar } = useSnackbar();

  const trpcUtils = trpc.useUtils();
  const terminanfrageMutation = trpc.thema.updateTerminanfrage.useMutation({
    onSuccess: async () => {
      enqueueSnackbar(terminanfrage ? content.snackBarSuccessManuell : content.snackBarSuccessAutomatisch, { variant: 'success' });
      setIsEditMode(false);
      await trpcUtils.planung.invalidate();
      await trpcUtils.thema.invalidate();
    },
    onError: () => {
      enqueueSnackbar(content.snackBarFail, { variant: 'error' });
      setIsEditMode(false);
    },
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setTerminanfrage(event.target.checked);
  };

  const cancelEditMode = (): void => {
    if (isEditMode) {
      setIsEditMode(false);
      setTerminanfrage(planungsinformation.manuelleTerminanfrage);
    }
  };

  const save = async (): Promise<void> => {
    await terminanfrageMutation.mutateAsync({ id: themaId, isTerminanfrageManuell: terminanfrage });
  };

  return (
    <Stack>
      <EditButtonGroup isEditMode={isEditMode} setEditMode={setIsEditMode} onSave={save} onCancel={cancelEditMode} />
      <FormControlLabel
        disabled={!isEditMode}
        sx={{
          '&MuiSwitch-switchBase.Mui-checked.Mui-disabled': { opacity: 0.4, color: 'black !important' },
          '& .MuiFormControlLabel-label.Mui-disabled': { opacity: 1, color: 'black !important' },
        }}
        control={<Switch checked={terminanfrage} onChange={handleChange} />}
        label="Manuelle Terminanfrage"
      />
    </Stack>
  );
};
