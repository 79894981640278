export const content = {
  columnLabels: {
    buchungsnummer: 'Buchungsnummer',
    titel: 'Titel',
    akaProduktId: 'AKA-Produkt-ID',
    verknuepfungBlopUndPraesenzseminar: 'Verknüpfung (B)LOP',
    akaVeranstaltungId: 'AKA-Veranstaltung-ID',
    vaAblauf: 'VA-Ablauf',
    vaStatus: 'VA-Status',
    trainer: 'Trainer:in',
    blockungsablauf: 'Blockungsablauf',
    terminanfrageStatus: 'Terminanfrage-Status',
    terminanfrageAnfrageDatum: 'Terminanfrage-Anfragedatum',
  },
  tooltips: {
    zurVeranstaltungWechseln: 'Zur Veranstaltung wechseln',
    zumThemaWechseln: 'Zum Thema wechseln',
    zuHiveWechseln: 'Zu Hive wechseln',
  },
  noInfoAvailable: '-',
} as const;
