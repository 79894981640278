import { clusterzuordnung, type ExperteFromResourcesRouter, type ressourcenBlockungTypes } from '../../../../../dtos';
import { type content } from './CalendarResourceOverview.content';
import { type CalendarExperteResource, type CalendarRaumResource, type CalendarResource, type CalendarStandortResource, isExperteResource } from './helperClasses';
import { type MbscResource } from '@mobiscroll/react';

export type FilterParameters = {
  region: number[];
  standort: number[];
  orte: number[];
  raumgroesse: number | null;
  ortHatGruppenraum: boolean | null;
};

export type StandortResourceGroup = Omit<MbscResource, 'id' | 'name' | 'children'> & {
  id: typeof ressourcenBlockungTypes.STANDORT;
  name: typeof content.calenderResourceGroupLabels.STANDORT;
  children: CalendarStandortResource[];
};

export type RaumResourceGroup = Omit<MbscResource, 'id' | 'name' | 'children'> & {
  id: typeof ressourcenBlockungTypes.RAUM;
  name: typeof content.calenderResourceGroupLabels.RAUM;
  children: CalendarRaumResource[];
};

export type ExperteResourceGroup = Omit<MbscResource, 'id' | 'name' | 'children'> & {
  id: typeof ressourcenBlockungTypes.EXPERTE;
  name: typeof content.calenderResourceGroupLabels.EXPERTE;
  children: Array<HaupttrainerResourceGroup | NebentrainerResourceGroup | ErsatztrainerResourceGroup | TrainerOhneZuordnungResourceGroup>;
};

type HaupttrainerResource = CalendarExperteResource & { experte: ExperteFromResourcesRouter & { clusterzuordnung: typeof clusterzuordnung.HAUPTTRAINER } };
export const isHaupttrainerResource = (resource: CalendarResource): resource is HaupttrainerResource =>
  isExperteResource(resource) && resource.experte.clusterzuordnung === clusterzuordnung.HAUPTTRAINER;

export type HaupttrainerResourceGroup = {
  id: typeof clusterzuordnung.HAUPTTRAINER;
  name: string;
  children: HaupttrainerResource[];
};

type NebentrainerResource = CalendarExperteResource & { experte: ExperteFromResourcesRouter & { clusterzuordnung: typeof clusterzuordnung.NEBENTRAINER } };
export const isNebentrainerResource = (resource: CalendarResource): resource is NebentrainerResource =>
  isExperteResource(resource) && resource.experte.clusterzuordnung === clusterzuordnung.NEBENTRAINER;

export type NebentrainerResourceGroup = {
  id: typeof clusterzuordnung.NEBENTRAINER;
  name: string;
  children: NebentrainerResource[];
};

type ErsatztrainerResource = CalendarExperteResource & { experte: ExperteFromResourcesRouter & { clusterzuordnung: typeof clusterzuordnung.ERSATZTRAINER } };
export const isErsatztrainerResource = (resource: CalendarResource): resource is ErsatztrainerResource =>
  isExperteResource(resource) && resource.experte.clusterzuordnung === clusterzuordnung.ERSATZTRAINER;

export type ErsatztrainerResourceGroup = {
  id: typeof clusterzuordnung.ERSATZTRAINER;
  name: string;
  children: ErsatztrainerResource[];
};

type TrainerOhneZuordnungResource = CalendarExperteResource & { experte: ExperteFromResourcesRouter & { clusterzuordnung: typeof clusterzuordnung.KEINEZUORDNUNG } };
export const isTrainerOhneZuordnungResource = (resource: CalendarResource): resource is TrainerOhneZuordnungResource =>
  isExperteResource(resource) && resource.experte.clusterzuordnung === clusterzuordnung.KEINEZUORDNUNG;

export type TrainerOhneZuordnungResourceGroup = {
  id: typeof clusterzuordnung.KEINEZUORDNUNG;
  name: string;
  children: TrainerOhneZuordnungResource[];
};

export type CollapseInfo = {
  experte: boolean;
  raum: boolean;
  standort: boolean;
};
