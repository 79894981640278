import { type GetRaeumeOutput } from '../../../../../dtos';
import { parseNumberOrString } from '../../../../../utils/number';

export const hatGruppenraumValues = [
  { value: false, label: 'nicht vorhanden' },
  { value: true, label: 'vorhanden' },
];

export const getUniqueOrteFromRaeume = (raeume: GetRaeumeOutput): GetRaeumeOutput => {
  const ort: Record<string, GetRaeumeOutput[number]> = {};

  for (const raum of raeume) {
    ort[raum.ort.kuerzel] = raum;
  }

  return Object.values(ort);
};

export const parseRaumgroesse = (str: string): number | null => {
  const value = parseNumberOrString(str);
  return typeof value === 'string' || value < 0 ? null : value;
};
