import { content as terminContent } from '../content';

export const content = {
  ...terminContent,
  draggableId: 'expertepool',
  heading: 'Trainer:innenpool',
  subheading: 'Anteil Haupt/Nebentrainer:innen in %:',
  columnLabels: {
    name: 'Name',
    anrede: 'Anrede',
    titel: 'Titel',
    cluster: 'Cluster',
    individuelleProzentverteilung: 'Individuelle %-Verteilung',
    auslastung: 'Auslastung',
    einsatzort: 'Einsatzort',
    ausschlusszeiten: 'Ausschlusszeiten',
    gesellschaft: 'Gesellschaft',
    email: 'E-Mail',
    sapId: 'SAP-ID',
  },
} as const;

export type Content = typeof content;
