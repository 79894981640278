import { TrpcLoadingInfo } from '../../components/general/TrpcLoadingInfo';
import { trpc } from '../../trpc';
import { VeranstaltungSearchDataGrid } from './VeranstaltungSearchDataGrid';
import { Box, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router';

export const VeranstaltungSearchPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get('searchTerm') ?? '');
  const veranstaltungQuery = trpc.search.searchVeranstaltungen.useQuery({ searchTerm }, { enabled: searchTerm.length > 0 });

  const { data } = veranstaltungQuery;

  const updateSearchTerm = (term: string): void => {
    setSearchParams({ searchTerm: term });
    setSearchTerm(term);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '84vh',
        gap: 2,
        padding: 2,
        boxSizing: 'border-box',
      }}
    >
      <Stack direction="row" justifyContent="center" sx={{ marginTop: 2, marginBottom: 0 }}>
        <TextField label="Suche" onChange={(event) => updateSearchTerm(event.target.value)} sx={{ width: '50vw' }} value={searchTerm} variant="outlined" />
      </Stack>
      <Box sx={{ flexGrow: 1, minHeight: 0 }}>
        <TrpcLoadingInfo trpcQuery={veranstaltungQuery} entity="Veranstaltungen">
          {data && <VeranstaltungSearchDataGrid data={data} />}
        </TrpcLoadingInfo>
      </Box>
    </Box>
  );
};
