import { dismissSnackbarAction } from './dismissSnackbarAction';
import { type SnackbarGenerator } from './utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert } from '@mui/material';
import { type TRPCClientErrorBase } from '@trpc/client';
import { type DefaultErrorShape } from '@trpc/server/unstable-core-do-not-import';
import { closeSnackbar, type CustomContentProps, type OptionsWithExtraProps, SnackbarContent } from 'notistack';
import { forwardRef } from 'react';
import { Link } from 'react-router';

const createVeranstaltungSnackbarOptions: OptionsWithExtraProps<'error'> = {
  variant: 'error',
  persist: true,
  action: dismissSnackbarAction,
};

export const generateCreateVeranstaltungErrorSnackbar: SnackbarGenerator<TRPCClientErrorBase<DefaultErrorShape>> = (error) => ({
  snackbarNode: (
    <div>
      <p>Es trat ein Fehler beim Speichern der Veranstaltung auf.</p>
      <p>{error.message}</p>
    </div>
  ),
  options: createVeranstaltungSnackbarOptions,
});

export const generateCreateVeranstaltungErrorSnackbarWithFehlerId: SnackbarGenerator<{ error: TRPCClientErrorBase<DefaultErrorShape>; fehlerId: string }> = ({
  error,
  fehlerId,
}) => ({
  snackbarNode: (
    <div>
      <p>Es trat ein Fehler beim Speichern der Veranstaltung auf. Wende dich bitte mit Fehler-ID</p>
      <p>{fehlerId}</p>
      <p>an das VAMOS Team.</p>
      <p>{error.message}</p>
    </div>
  ),
  options: createVeranstaltungSnackbarOptions,
});

type CreateVeranstaltungSuccessSnackbarProps = {
  readonly veranstaltungId: number;
} & CustomContentProps;

// Custom Snackbar: Write your custom snackbar component like this!
export const CreateVeranstaltungSuccessSnackbar = forwardRef<HTMLDivElement, CreateVeranstaltungSuccessSnackbarProps>((props, ref) => {
  const { id, veranstaltungId, ...other } = props;

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <Alert variant="filled" severity="success" icon={<CheckCircleIcon fontSize="small" />} sx={{ alignItems: 'center' }} onClose={() => closeSnackbar(id)}>
        Veranstaltung mit ID <Link to={`/va/${veranstaltungId}`}>{veranstaltungId}</Link> gespeichert!
      </Alert>
    </SnackbarContent>
  );
});
