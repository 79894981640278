import MerriweatherSansLightTtf from '../assets/fonts/MerriweatherSans-300.ttf';
import MerriweatherSansLightWoff from '../assets/fonts/MerriweatherSans-300.woff';
import MerriweatherSansLightWoff2 from '../assets/fonts/MerriweatherSans-300.woff2';
import MerriweatherSansRegularTtf from '../assets/fonts/MerriweatherSans-400.ttf';
import MerriweatherSansRegularWoff from '../assets/fonts/MerriweatherSans-400.woff';
import MerriweatherSansRegularWoff2 from '../assets/fonts/MerriweatherSans-400.woff2';
import MerriweatherSansBoldTtf from '../assets/fonts/MerriweatherSans-700.ttf';
import MerriweatherSansBoldWoff from '../assets/fonts/MerriweatherSans-700.woff';
import MerriweatherSansBoldWoff2 from '../assets/fonts/MerriweatherSans-700.woff2';
import colorPalette from './colorPalette';
import { createTheme, styled } from '@mui/material/styles';
import { MaterialDesignContent } from 'notistack';

const lightHouseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1_170,
      xl: 1_920,
    },
  },
  palette: {
    primary: {
      main: colorPalette.primary.main,
      dark: colorPalette.primary.dark,
      light: colorPalette.primary.light,
    },
    text: {
      primary: colorPalette.text.primary,
      secondary: colorPalette.text.secondary,
    },
    error: {
      main: colorPalette.colors.crimson,
    },
  },
  typography: {
    fontFamily: 'Merriweather Sans, Arial',
  },
  components: {
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: `
          @font-face {
            font-family: 'Merriweather Sans';
            font-style: normal;
            font-weight: 300;
            src: url(${MerriweatherSansLightWoff2}) format('woff2'),
                url(${MerriweatherSansLightWoff}) format('woff'),
                url(${MerriweatherSansLightTtf}) format('truetype');
          }
          @font-face {
            font-family: 'Merriweather Sans';
            font-style: normal;
            font-weight: 400;
            src: url(${MerriweatherSansRegularWoff2}) format('woff2'),
                url(${MerriweatherSansRegularWoff}) format('woff'),
                url(${MerriweatherSansRegularTtf}) format('truetype');
          }
          @font-face {
            font-family: 'Merriweather Sans';
            font-style: normal;
            font-weight: 700;
            src: url(${MerriweatherSansBoldWoff2}) format('woff2'),
                url(${MerriweatherSansBoldWoff}) format('woff'),
                url(${MerriweatherSansBoldTtf}) format('truetype');
          }
          background-color: #fafafa;
        `,
      },
    },
    // BUTTON
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
  },
});

// name was recommended by notistack documentation
// eslint-disable-next-line @typescript-eslint/naming-convention
export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: lightHouseTheme.palette.success.main,
    color: lightHouseTheme.palette.common.white,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: lightHouseTheme.palette.error.main,
    color: lightHouseTheme.palette.common.white,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: lightHouseTheme.palette.primary.main,
    color: lightHouseTheme.palette.common.white,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: lightHouseTheme.palette.warning.main,
    color: lightHouseTheme.palette.common.white,
  },
}));

export default lightHouseTheme;
