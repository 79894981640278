import { type StatusChangeResult } from '../../dtos';
import { type BaseVariant, type OptionsWithExtraProps, type SnackbarMessage } from 'notistack';

export const generateFailureReasonList = (veranstaltung: StatusChangeResult): SnackbarMessage => (
  <ul>
    {veranstaltung.errorMessages.map((errorMessage) => (
      <li key={`${veranstaltung.id} - ${errorMessage}`}>{errorMessage}</li>
    ))}
  </ul>
);

export const backendErrorMessage = 'Backend-Fehler beim Ändern des Status.';

/**
 * ## What is this?
 * 
 * This is a helper type for writing your own SnackbarGenerator function.
 * 
 * ## When do I use it?
 * 
 * A SnackbarGenerator function is the right choice, in our code, for snackbars that need some ***static input*** and ***display that input with ReactNodes***.
 * 
 * - ***static input*** means your snackbar won't need rerendering if your input should change (so it doesn't need to "react" to anything).
 * - ***display that input with ReactNodes*** means you need `<p>` or `<div>` or other basic HTML elements.
 *   - React components that only apply styles (like <Box> from MUI) are also okay.
 * 
 * ## When do I not use it?
 * 
 * 1. If it has static input, but you just need to display it in a string, throw the string directly into the enqueueSnackbar function, like so:
 * 
 * ```typescript
 * enqueueSnackbar(`This is my message, and here I will show you the ID of something: ${something.Id}`, { variant: 'success', persist: true });
 * ```
 * 
 * 2. If it has ...
 * - ... a more complex display which uses react components (like `<Link>`) ...
 * - ... react hooks (like `useState` or `useEffect` ...
 * - ... non-static input that you want it to rerender for ...
 * 
 * ... then write a real custom snackbar component (https://notistack.com/features/customization#custom-component).
 * An example for this is CreateVeranstaltungSuccessSnackbar. Seach the code for `// Custom Snackbar` to see how to do this in our code!
 *
 * ## How do I use it?
 * 
 * Write a SnackbarGenerator function:
 * 
 * ```tsx
 * const generateThisComponentSuccessSnackbar: SnackbarGenerator<number> = (id) => {
 *   return {
 *     snackbarNode:
 *       <div>
 *         <p>Hello!<p>
 *         <p>I have something to tell you: {id}</p>
 *       </div>
 *     options: { variant: 'success', persist: true }
 *   };
 * };
 * ```
 * 
 * Afterwards, you can use it like so:
 * 
 * ```tsx
 * const snackbar = generateThisComponentSuccessSnackbar(something.Id);
 * enqueueSnackbar(snackbar.snackbarNode, snackbar.options);
 * ````
 * 

 * 
 * @typeParam Props - Any custom property you want to pass to your function.
 */
export type SnackbarGenerator<Props> = (props: Props) => {
  snackbarNode: SnackbarMessage;
  options: OptionsWithExtraProps<BaseVariant>;
};
