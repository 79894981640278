import { type GetAutomatisierteTerminanfragenByThemaInput } from '../../../dtos';
import { type AutomatisierteTerminanfragenFilterConfiguration, buttonStyle, quickfilterConfigurations } from './AutomatisierteTerminanfragenFilter.utils';
import { DeleteForever } from '@mui/icons-material';
import { Box, Button, ToggleButton, Tooltip, useTheme } from '@mui/material';
import { useState } from 'react';

type AutomatisierteTerminanfragenFilterProps = {
  readonly isLoading?: boolean;
  readonly setTerminanfrageFilterConfiguration: (configuration: GetAutomatisierteTerminanfragenByThemaInput) => void;
};

export const AutomatisierteTerminanfragenFilter: React.FC<AutomatisierteTerminanfragenFilterProps> = ({
  isLoading,
  setTerminanfrageFilterConfiguration,
}: AutomatisierteTerminanfragenFilterProps) => {
  const { palette } = useTheme();

  const [selected, setSelected] = useState<string | undefined>(undefined);

  const handleFilterSelection = (config: AutomatisierteTerminanfragenFilterConfiguration): void => {
    setSelected((previousValue) => {
      if (previousValue === config.key) {
        setTerminanfrageFilterConfiguration();
        return undefined;
      }

      setTerminanfrageFilterConfiguration(config.filter);
      return config.key;
    });
  };

  const resetFilters = (): void => {
    setSelected(undefined);
    setTerminanfrageFilterConfiguration();
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center" mb={1} mt={1} fontSize=".9rem" gap={3}>
      <Box color={palette.primary.main}>FILTER:</Box>
      {quickfilterConfigurations.map((configuration) => (
        <Tooltip key={configuration.key} arrow title={configuration.tooltip} disableInteractive>
          <ToggleButton
            value={configuration.key}
            selected={selected === configuration.key}
            disabled={isLoading}
            sx={buttonStyle(palette)}
            onChange={() => handleFilterSelection(configuration)}
          >
            {configuration.label}
          </ToggleButton>
        </Tooltip>
      ))}
      <Button disabled={isLoading} onClick={resetFilters}>
        <Box display="flex" alignItems="center" gap={1}>
          <DeleteForever />
          Alle entfernen
        </Box>
      </Button>
    </Box>
  );
};
