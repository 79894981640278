import { EasyBreadcrumbs, type EasyBreadcrumbsItem } from '../../components/general/EasyBreadcrumbs';
import { AutomatisierteTerminanfragenFilter } from '../../components/pages/reporting/AutomatisierteTerminanfragenFilter';
import { AutomatisierteTerminanfragenTable } from '../../components/pages/reporting/AutomatisierteTerminanfragenTable';
import { type GetAutomatisierteTerminanfragenByThemaInput, type GetAutomatisierteTerminanfragenByThemaOutput } from '../../dtos';
import { trpc } from '../../trpc';
import { BackendConfigContext } from '../../utils/BackendInfrastructure';
import { Alert, Box } from '@mui/material';
import { useContext, useState } from 'react';

export const AutomatisierteTerminanfragenPage: React.FC = () => {
  const backendConfig = useContext(BackendConfigContext);

  const breadcrumbs: EasyBreadcrumbsItem[] = [
    {
      href: '/',
      label: 'Dashboard',
    },
    {
      href: '/terminanfragen/automatisiert',
      label: 'Automatiserte Terminanfragen - Statusübersicht',
    },
  ];

  const [terminanfrageFilterConfiguration, setTerminanfrageFilterConfiguration] = useState<GetAutomatisierteTerminanfragenByThemaInput | undefined>();

  const themenForTermincheckQuery = trpc.reporting.getAutomatisierteTerminanfragenByThema.useQuery<GetAutomatisierteTerminanfragenByThemaOutput>(terminanfrageFilterConfiguration);

  return (
    <Box sx={{ marginTop: 2 }} display="flex" flexDirection="column">
      <EasyBreadcrumbs items={breadcrumbs} />

      <Box sx={{ marginTop: 1.5 }} display="flex" flexDirection="column" justifyContent="space-between" gap={1.5} height="80vh">
        <AutomatisierteTerminanfragenFilter isLoading={themenForTermincheckQuery.isLoading} setTerminanfrageFilterConfiguration={setTerminanfrageFilterConfiguration} />
        <AutomatisierteTerminanfragenTable
          data={themenForTermincheckQuery.data ?? []}
          isLoading={themenForTermincheckQuery.isLoading}
          hiveUrl={backendConfig.HiveAppUrl}
          filterReset={terminanfrageFilterConfiguration === undefined}
        />
        <Alert severity="info">
          Die Tabelle enthält nur Produkte mit Veranstaltungen, die noch relevant sind. Das heißt, dass der Veranstaltungsbeginn in der Zukunft liegen muss.
        </Alert>
      </Box>
    </Box>
  );
};
