import { type GetVeranstaltungByIdOutput } from '../../../dtos';
import { trpc } from '../../../trpc';
import { BackendConfigContext } from '../../../utils/BackendInfrastructure';
import { experteName } from '../../../utils/experteUtils';
import { getIsOnlineVeranstaltung, getOrtKuerzel, getRegionName } from '../../../utils/getBezeichnungen';
import { VaStatusChip } from '../../general/VaStatusChip';
import { Alert, Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useContext, useMemo } from 'react';

type RecentVeranstaltungenProps = {
  readonly themaId: number;
};

const SkeletonRow: React.FC = () => (
  <TableRow>
    <TableCell component="th" scope="row">
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      {' '}
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      {' '}
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      {' '}
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      <Skeleton />
    </TableCell>
  </TableRow>
);

const navigateAsync = async (vaID: number, vivaLegacyAppUrl: string): Promise<void> => {
  window.open(`https://${vivaLegacyAppUrl}/veranstaltungen/${vaID}`, '_blank');
};

type VeranstaltungsRowProps = { readonly veranstaltung: GetVeranstaltungByIdOutput };

const VeranstaltungsRow: React.FC<VeranstaltungsRowProps> = (props: VeranstaltungsRowProps) => {
  const { veranstaltung } = props;
  const backendConfig = useContext(BackendConfigContext);

  const startFmt = veranstaltung.ablauf.at(0)?.start.toLocaleString() ?? '';
  const endFmt = veranstaltung.ablauf.at(-1)?.end.toLocaleString() ?? '';

  const allRoomKuerzel = useMemo(() => veranstaltung.raumBlockungen.map((raumBlockung) => raumBlockung.raum.kuerzel).join(', '), [veranstaltung]);
  const allExperten = useMemo(() => veranstaltung.experteBlockungen.map((experteBlockung) => experteName(experteBlockung.experte)).join(', '), [veranstaltung]);

  const regionResult = getIsOnlineVeranstaltung(veranstaltung) ? 'Online' : getRegionName(veranstaltung);
  const hotelRaumResult = getIsOnlineVeranstaltung(veranstaltung) ? 'Zoom' : `${getOrtKuerzel(veranstaltung)} - ${allRoomKuerzel}`;

  return (
    <TableRow onClick={async () => veranstaltung.veranstaltungSapId && (await navigateAsync(veranstaltung.veranstaltungSapId, backendConfig.VivaLegacyAppUrl))}>
      <TableCell component="th" scope="row">
        {startFmt} - {endFmt}
      </TableCell>
      <TableCell align="right">{veranstaltung.veranstaltungSapId ?? ' - '}</TableCell>
      <TableCell align="right">
        <VaStatusChip sapStatus={veranstaltung.sapStatus} vivaStatus={veranstaltung.vivaStatus} />
      </TableCell>
      <TableCell align="right">{regionResult}</TableCell>
      <TableCell align="right">{hotelRaumResult}</TableCell>
      <TableCell align="right">{allExperten}</TableCell>
    </TableRow>
  );
};

export const RecentVeranstaltungen: React.FC<RecentVeranstaltungenProps> = (props: RecentVeranstaltungenProps) => {
  const { themaId } = props;
  const veranstaltungenForThema = trpc.veranstaltung.getVeranstaltungenByThemaId.useQuery({ themaId });

  return (
    <Box>
      {veranstaltungenForThema.isError ? (
        <Alert sx={{ paddingTop: 2 }} severity="error">
          Es trat ein Fehler beim Laden der Veranstaltungen auf
        </Alert>
      ) : (
        <Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Datum</TableCell>
                  <TableCell align="right">E-ID</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Region</TableCell>
                  <TableCell align="right">Hotel/Raum</TableCell>
                  <TableCell align="right">Trainer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {veranstaltungenForThema.isLoading && Array.from(Array.from({ length: 5 }).keys()).map((idx) => <SkeletonRow key={idx} />)}
                {veranstaltungenForThema.isSuccess && veranstaltungenForThema.data.slice(0, 4).map((vas) => <VeranstaltungsRow key={vas.veranstaltungSapId} veranstaltung={vas} />)}
              </TableBody>
            </Table>
          </TableContainer>
          {veranstaltungenForThema.isSuccess && veranstaltungenForThema.data.length === 0 && (
            <Box sx={{ paddingTop: 2 }}>
              <Alert severity="info">Es wurden keine Veranstaltungen gefunden</Alert>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
