import { type RessourcenBlockungen, type ThemaFromThemaRouter, type Veranstaltung } from '../../../dtos';
import { trpc } from '../../../trpc';
import { getIsOnlineVeranstaltung } from '../../../utils/getBezeichnungen';
import { getVeranstaltungStart } from '../../../utils/getVeranstaltungStartEnd';
import { CalendarResourceOverview } from '../thema/planung/CalendarResourceOverview';
import { Button, Stack } from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers-pro';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type UpdateRessourcenBlockungenProps = {
  readonly thema: Pick<ThemaFromThemaRouter, 'id' | 'planungsinformation' | 'manuelleTerminanfrage' | 'isOnline'>;
  readonly veranstaltung: Pick<Veranstaltung, 'ablauf' | 'akaVeranstaltungId' | 'id' | 'onlineTool'>;
};

export const UpdateRessourcenBlockungen: React.FC<UpdateRessourcenBlockungenProps> = (props: UpdateRessourcenBlockungenProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const trpcUtils = trpc.useUtils();
  const resourceMutation = trpc.veranstaltung.updateRessourcenBlockungenOfVeranstaltung.useMutation({
    onSuccess: () => {
      void trpcUtils.invalidate();
    },
    onError: () => {
      enqueueSnackbar('Speichern der Ressourcen fehlgeschlagen.', { variant: 'error' });
      void trpcUtils.invalidate();
    },
  });

  const onSave = async (ressourcenBlockungen: RessourcenBlockungen): Promise<void> => {
    setIsOpen(false);
    await resourceMutation.mutateAsync({
      veranstaltungId: props.veranstaltung.id,
      blockungen: ressourcenBlockungen,
      isOnlineVeranstaltung: getIsOnlineVeranstaltung(props.veranstaltung),
    });
    enqueueSnackbar('Ressourcenblockungen gespeichert.', { variant: 'success' });
  };

  return (
    <Stack>
      <Button onClick={() => setIsOpen(true)} size="small" startIcon={<CalendarIcon />} sx={{ borderRadius: 1 }}>
        {' '}
        Ressourcen ändern{' '}
      </Button>
      {isOpen ? (
        <CalendarResourceOverview
          thema={props.thema}
          veranstaltung={props.veranstaltung}
          onSave={onSave}
          onCancel={() => setIsOpen(false)}
          isFullscreen
          toggleFullscreen={() => setIsOpen(false)}
          initDate={getVeranstaltungStart(props.veranstaltung)}
        />
      ) : null}
    </Stack>
  );
};
