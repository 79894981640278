import { type Keyify, type RessourcenBlockungType } from '../../../../../dtos';
import { clusterzuordnungLabels } from '../../../../../utils/clusterzuordnung';

const calenderResourceGroupLabels = {
  EXPERTE: 'Trainer',
  RAUM: 'Räume',
  STANDORT: 'Standorte',
} as const satisfies Record<Keyify<RessourcenBlockungType>, string>;

export const content = {
  clusterzuordnungLabels,
  calenderResourceGroupLabels,
} as const;
